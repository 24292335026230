// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-index-jsx": () => import("./../../../src/templates/Index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-info-jsx": () => import("./../../../src/templates/Info.jsx" /* webpackChunkName: "component---src-templates-info-jsx" */),
  "component---src-templates-not-found-jsx": () => import("./../../../src/templates/NotFound.jsx" /* webpackChunkName: "component---src-templates-not-found-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

