/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery")

export const onInitialClientRender = () => {
    $(window).on('scroll', function () {

        $('.flip').each(function () {
            var elem = $(this);

            var scrollTop = $(window).scrollTop(),
                parentElementOffsetTop = elem.parent().offset().top,
                parentDistanceTop = (parentElementOffsetTop - scrollTop);
            var elementHeight = elem.height();
            var distanceBottom = (parentDistanceTop + elementHeight);
            var opacity = (distanceBottom / elementHeight);
            var rotateSpeed = ((parentDistanceTop / 8).toFixed(2) * -1);



            if (rotateSpeed <= 0) {
                rotateSpeed = 0;
            } else if (rotateSpeed >= 90) {
                rotateSpeed = 90;
                opacity = 0;
            }
            elem.css({
                "transform": " translateY(0px)  rotateX(" + rotateSpeed + "deg)",
                // "opacity": opacity
            });

        });




    });
}
